<template>
  <div class="mkt-project guit">
    <div class="proj-header">
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-left"
        @click="navProject({ type: 'mkt', index: -1 })"
      />
      <h2>{{ selectedProj.title }}</h2>
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-right"
        @click="navProject({ type: 'mkt', index: 1 })"
      />
    </div>

    <div class="media-cont">
      <div class="col1">
        <div class="desc">
          <p>
            {{ selectedProj.description }}
          </p>
        </div>
        <img
          class="two"
          src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_00_07.Still001.jpg"
          @click="fullscreen({ list: selectedProj.media, index: 1 })"
        />
      </div>

      <div class="col2">
        <div class="row one">
          <video playsinline autoplay loop muted>
            <source
              src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.m4v"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <img
            class="one"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_19_02.Still007.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 0 })"
          />
        </div>

        <div class="row two">
          <img
            class="one"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/guitare/Guitar.00_00_14_19.Still006.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 0 })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedProj: Object,
    navProject: Function,
    fullscreen: Function
  }
};
</script>
