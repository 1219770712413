<template>
  <div class="mkt-project bols">
    <div class="proj-header">
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-left"
        @click="navProject({ type: 'mkt', index: -1 })"
      />
      <h2>{{ selectedProj.title }}</h2>
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-right"
        @click="navProject({ type: 'mkt', index: 1 })"
      />
    </div>

    <div class="media-cont">
      <div class="col1">
        <img
          class="one"
          :src="selectedProj.media[0].url"
          @click="fullscreen({ list: selectedProj.media, index: 0 })"
        />
        <img
          class="two"
          :src="selectedProj.media[1].url"
          @click="fullscreen({ list: selectedProj.media, index: 1 })"
        />
      </div>

      <div class="col2">
        <div class="row one">
          <div class="desc">
            <p>
              {{ selectedProj.description }}
            </p>
          </div>

          <video playsinline autoplay loop muted>
            <source
              src="https://allpha.s3.ca-central-1.amazonaws.com/src/Allpha.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="row two">
          <img
            class="two"
            :src="selectedProj.media[3].url"
            @click="fullscreen({ list: selectedProj.media, index: 3 })"
          />
          <img
            class="one"
            :src="selectedProj.media[2].url"
            @click="fullscreen({ list: selectedProj.media, index: 2 })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedProj: Object,
    navProject: Function,
    fullscreen: Function
  }
};
</script>
