<template>
  <div class="services mob">
    <div
      class="root mob"
      :class="{
        collapsed: service !== null,
        left: service === 'marketing',
        right: service === 'production'
      }"
    >
      <div class="trapeze left" :class="{ selected: service === 'marketing' }">

        <transition name="selected-comp" mode="out-in">
          <img
          @click="selectService('marketing')"
          :src="mktSelection.cover.url"
          />
        </transition>

        <h3 @click="selectService('marketing')">marketing</h3>
        <!-- <p class='main-desc'>
          Des produits digitales époustouflant afin de promovoir vos produits et
          services.
        </p> -->

        <transition name="selected-comp" mode="out-in">
          <p class='proj-desc'>
            {{mktSelection.description}}
          </p>
        </transition>

      </div>
      <div
        class="trapeze right"
        :class="{ selected: service === 'production' }"
      >
      <transition name="selected-comp" mode="out-in">
        <img
        @click="selectService('production')"
        :src="prodSelection.cover.url"
        />
      </transition>

        <h3 @click="selectService('production')">production</h3>
        <!-- <p class='main-desc'>
          La production vous permet de créer du contenu pour toutes autres fins
          que publicitaires...
        </p> -->

        <transition name="selected-comp" mode="out-in">
          <p>
            {{prodSelection.description}}
          </p>
        </transition>

      </div>
    </div>

    <transition name="selected-comp" mode="out-in">
      <component
        v-if="service !== null"
        :is="projComponent"
        :fullscreen="setModal"
        :navProject="navProject"
        :selectedProj="projSelection"
      ></component>
    </transition>

    <!-- <component v-if='service!==null' :is='projComponent' :navProject="navProject" :mktSelection='mktSelection' :prodSelection='prodSelection'></component> -->

    <!-- <Marketing v-if='service==="marketing"' :navProject="navProject" :selectedProj='mktSelection' />
    <Production v-else :navProject="navProject" :selectedProj='prodSelection' /> -->
  </div>
</template>

<script>
import portfolio from "../mediaSrc/portfolio.js";
import Marketing from "../components/Marketing.vue";
import Production from "../components/Production.vue";
import BolsAPatProj from "../components/project/BolsAPatProj.vue";
import TheRunnerProj from "../components/project/TheRunnerProj.vue";
import LeconGuitarProj from "../components/project/LeconGuitarProj.vue";
import TeaserAxyoProj from "../components/project/TeaserAxyoProj.vue";
import EtirementProj from "../components/project/EtirementProj.vue";

export default {
  components: {
    Marketing,
    Production,
    BolsAPatProj,
    TheRunnerProj,
    LeconGuitarProj,
    TeaserAxyoProj,
    EtirementProj
  },
  props: {
    setModal: Function
  },
  data() {
    return {
      service: null,
      mktIndex: 0,
      prodIndex: 0
    };
  },
  methods: {
    selectService(svc) {
      this.service = svc;
    },
    navProject(data) {
      let { type, index } = data;

      if (type === "mkt") {
        if (
          (index === -1 && this.mktIndex === 0) ||
          (this.mktIndex + 1 === portfolio.marketing.length && index === 1)
        ) {
          return;
        } else {
          this.mktIndex = this.mktIndex + index;
          return;
        }
      } else if (type === "prod") {
        if (
          (index === -1 && this.prodIndex === 0) ||
          (this.prodIndex + 1 === portfolio.production.length && index === 1)
        ) {
          return;
        }
        this.prodIndex = this.prodIndex + index;
        return;
      }
    }
  },
  computed: {
    selProject() {
      return portfolio[this.service][this.mktIndex];
    },
    projComponent() {
      let comp;

      switch (
        portfolio[this.service][
          this.service === "marketing" ? this.mktIndex : this.prodIndex
        ].title
      ) {
        case "les bols à Pat":
          comp = "BolsAPatProj";
          break;

        case "the runner":
          comp = "TheRunnerProj";
          break;

        case "leçon de guitt":
          comp = "LeconGuitarProj";
          break;

        case "Teaser AXYO":
          comp = "TeaserAxyoProj";
          break;

        case "L'étirement, c'est important":
          comp = "EtirementProj";
          break;

        default:
      }
      return comp;
    },
    projSelection() {
      if (this.service === "marketing") {
        return portfolio.marketing[this.mktIndex];
      } else if (this.service === "production") {
        return portfolio.production[this.prodIndex];
      } else {
        return null;
      }
    },
    mktSelection() {
      return portfolio.marketing[this.mktIndex];
    },
    prodSelection() {
      return portfolio.production[this.prodIndex];
    }
  }
};
</script>
