<template>
  <div class="mkt-project run">
    <div class="proj-header">
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-left"
        @click="navProject({ type: 'mkt', index: -1 })"
      />
      <h2>{{ selectedProj.title }}</h2>
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-right"
        @click="navProject({ type: 'mkt', index: 1 })"
      />
    </div>

    <div class="media-cont">
      <div class="col1">
        <video playsinline autoplay loop muted>
          <source
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/SV+-+FR+-+Mus_2.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <img
          class="two"
          src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_01_00_21.Still018.jpg"
          @click="fullscreen({ list: selectedProj.media, index: 3 })"
        />
      </div>

      <div class="col2">
        <div class="row one">
          <div class="desc">
            <p>
              {{ selectedProj.description }}
            </p>
          </div>
          <img
            class="one"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_10_11.Still005.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 1 })"
          />
        </div>

        <div class="row two">
          <img
            class="three"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_05_23.Still003.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 0 })"
          />
          <img
            class="two"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/mkt/the_runner/LV+-+FR.00_00_20_12.Still011.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 2 })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedProj: Object,
    navProject: Function,
    fullscreen: Function
  }
};
</script>
