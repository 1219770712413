<template>
  <div class='mkt-project'>
    <div class='proj-header'>
      <FontAwesomeIcon class='nav-icon' icon='chevron-left' @click='navProject({type: "mkt", index: -1})'/>
      <h2>{{selectedProj.title}}</h2>
      <FontAwesomeIcon class='nav-icon' icon='chevron-right' @click='navProject({type: "mkt", index: 1})'/>
    </div>

    <div class='media-cont'>
      <div class='col1'>
        <img class='one' :src="selectedProj.media[0].url"  />
        <img class='two' :src="selectedProj.media[1].url"  />
      </div>

      <div class='col2'>
        <div class='row'>
          <div class='desc'>
            <p>
              {{selectedProj.description}}
            </p>
          </div>
          <!-- <video width="320" height="240" autoplay>
          <source :src="preview.url" type="video/mp4">
          Your browser does not support the video tag.
        </video> -->
        <img class='one' :src="selectedProj.media[3].url"  />
      </div>

      <div class='row'>
        <img class='two' :src="selectedProj.media[2].url"  />
        <img class='three' :src="selectedProj.media[3].url"  />
      </div>
    </div>
    </div>

  </div>
</template>

<script>

export default{
  props: {
    selectedProj: Object,
    navProject: Function
  }
}
</script>
