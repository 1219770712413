<template>
  <div class="mkt-project bols">
    <div class="proj-header">
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-left"
        @click="navProject({ type: 'mkt', index: -1 })"
      />
      <h2>{{ selectedProj.title }}</h2>
      <FontAwesomeIcon
        class="nav-icon"
        icon="chevron-right"
        @click="navProject({ type: 'mkt', index: 1 })"
      />
    </div>

    <div class="media-cont">
      <div class="col1">
        <video playsinline autoplay loop muted>
          <source
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <!-- <img class='one' :src="selectedProj.media[0].url" @click='fullscreen({list: selectedProj.media, index:0})' /> -->
        <img
          class="two"
          src="https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_03_48_17.Still004.jpg"
          @click="fullscreen({ list: selectedProj.media, index: 1 })"
        />
      </div>

      <div class="col2">
        <div class="row">
          <div class="desc">
            <p>
              {{ selectedProj.description }}
            </p>
          </div>
          <img
            class="one"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_01_45_08.Still003.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 2 })"
          />
        </div>

        <div class="row">
          <img
            class="two"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_21_11.Still001.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 3 })"
          />
          <img
            class="three"
            src="https://allpha.s3.ca-central-1.amazonaws.com/app/prod/clinique/E%CC%81tirementfinal+-+HD+1080p.00_00_57_09.Still002.jpg"
            @click="fullscreen({ list: selectedProj.media, index: 4 })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedProj: Object,
    navProject: Function,
    fullscreen: Function
  }
};
</script>
